import React from "react";

const Terms = () => {
  return (
    <div className="privacy-wrapper">
      <h1 className="privacy privacy-heading privacy-heading-1">
        <strong>Terms of Service and end user license agreement</strong>
      </h1>
      <h4>
        <b>
          <em>Last Updated: September 28, 2022</em>
        </b>
      </h4>
      <br />
      <p className="privacy privacy-paragraph">
        These Terms of Service and End User License Agreement (“Terms”) are an
        agreement between you and Digital Base L.L.C-FZ. Throughout these Terms,
        the terms “Company,” “us,” “our”, “we” and/or “Digital Base” refer to
        Digital Base L.L.C-FZ. These Terms govern your access and use of our
        website, https://digitalbase.me, or any other site or application where
        these Terms are posted (collectively, the “Services”).
        <br />
        <br />
        BY ACCESSING OR DOWNLOADING OUR SERVICES, YOU (a) ACKNOWLEDGE THAT YOU
        HAVE READ, UNDERSTAND AND ACCEPT THESE TERMS AND AGREE THAT YOU ARE
        LEGALLY BOUND BY THEM. IF YOU DO NOT AGREE TO THESE TERMS, DO NOT ACCESS
        OR DOWNLOAD ANY OF THE SERVICES.
        <br />
        <br />
        THESE TERMS REQUIRE THE USE OF ARBITRATION TO RESOLVE DISPUTES, RATHER
        THAN JURY TRIALS OR CLASS ACTIONS, LIMIT THE TIME IN WHICH TO br /ING A
        CLAIM, AND ALSO LIMIT THE REMEDIES AVAILABLE TO YOU IN THE EVENT OF A
        DISPUTE.
        <br />
        <br />
        We may amend these Terms occasionally. If any changes to the Terms
        materially affect your rights or obligations, we will notify you by
        reasonable means. With respect to our mobile application, you
        acknowledge and agree that an in-app message which notifies you of any
        material changes when you open the mobile application constitutes
        reasonable means. Your continued use of the mobile application and/or
        any of the Services after we post any such amendments constitutes your
        acceptance thereof. If you do not agree to the amendments, you must
        discontinue use of the Services.
        <br />
        <br />
        <strong>
          AGE REQUIREMENT
          <br />
        </strong>
        The Services, and content therein, is intended for adults and children
        under the age of 13 to use only with the permission of and/or
        participation of a parent or guardian. If you do not meet all of these
        requirements, you must not access or use the Services.
        <br />
        <br />
        <strong>
          INTELLECTUAL PROPERTY OWNERSHIP
          <br />
        </strong>
        The Services and their entire contents, features, and functionality
        (including but not limited to all information, software, text, displays,
        graphics, images, video, and audio, and the design, selection, and
        arrangement thereof) are owned by Company, its licensors, service
        providers, or other providers of such material, and are protected by
        United States, Canadian, and international copyright, trademark, patent,
        trade secret, and other intellectual property or proprietary rights laws
        (collectively, the “Intellectual Property”).
        <br />
        <br />
        <strong>TRADEMARK RIGHTS</strong>Digital Base is the owner of pending,
        registered and unregistered trademarks, trade dress and trade names
        appearing on the Services, including but not limited to Company name and
        logo, and all related names, logos, products and service names, designs,
        slogans and tag lines (collectively the “Marks”). You must not use such
        marks without the prior written permission of Company. All other names,
        logos, product and service names, designs, slogans and tag lines on the
        Services are the trademarks of their respective owners.
        <br />
        <br />
        <strong>
          LICENSE GRANT
          <br />
        </strong>
        Subject to these Terms, Digital Base grants you (“Licensee”) a limited,
        non-exclusive, revocable, non-sublicensable, and nontransferable license
        to: (a) access, download, install, and use the Services for your
        personal, non-commercial use on a device owned or otherwise controlled
        by you (”Device”) strictly in accordance with the Services’
        documentation, and (b) access, stream, download, and use on such Device,
        interactive features made available in or otherwise accessible through
        the Services, strictly in accordance with these Terms.
        <br />
        <br />
        <strong>
          LICENSE RESTRICTIONS
          <br />
        </strong>
        Licensee shall not:
        <br />
        Copy any of the Services, except as expressly permitted by this license
        (and other than a single copy for your own backup purposes); modify,
        translate, adapt, or otherwise create derivative works or improvements,
        whether or not copyrightable and/or patentable, of the Services;reverse
        engineer, disassemble, decompile, decode, or otherwise attempt to derive
        or gain access to the source code of the Services or any part thereof;
        access, create and/or modify the Services’ source code; remove, delete,
        alter, or obscure any trademarks or any copyright, trademark, patent, or
        other intellectual property or proprietary rights notices from the
        Services,&nbsp; including any copy thereof; rent, lease, lend, sell,
        sublicense, assign, distribute, assign, publish, transfer, grant, or
        otherwise make available the Services, or any features or functionality
        of the Services, to any third party for any reason, including by making
        the Services available on a network where it is capable of being
        accessed and/or downloaded by more than one device at any time; use any
        spider, robot, and/or other automatic or manual device or process to
        interfere with the proper working of the Services (except to remove the
        Services that have been downloaded to your device); and/or remove,
        disable, circumvent, or otherwise create or implement any workaround to
        any copy protection, rights management, or security features in or
        protecting the Services.
        <br />
        <br />
        If you violate the Intellectual Property rights of Company and/or these
        Terms, your right to use the Services will stop immediately and you
        must, at our option, return or destroy any copies of the materials you
        have made. No right, title, or interest in or to the Services or any
        content on the Services is transferred to you, and all rights not
        expressly granted are reserved by Company. Any use of the Services not
        expressly permitted by these Terms is a br /each of these Terms and may
        violate copyright, trademark, and other laws.
        <br />
        <br />
        <strong>
          PROHIBITED USES
          <br />
        </strong>
        In addition to other restrictions set forth in these Terms, you are
        prohibited from using the Services or any of the content therein: (a)
        for any unlawful purpose or to solicit others to perform or participate
        in any unlawful acts; (b) to violate any applicable federal, provincial
        or state regulations, rules, laws, local ordinances, or international
        law or regulation (including, without limitation, any laws regarding the
        export of data or software to and from the US or other countries); (c)
        for the purpose of exploiting, harming, or attempting to exploit or harm
        minors in any way by exposing them to inappropriate content, asking for
        personally identifiable information, or otherwise; (d) to infringe upon
        or violate our Intellectual Property rights or the intellectual property
        rights of others; (e) to harass, abuse, insult, harm, defame, slander,
        disparage, intimidate, or discriminate based on gender, sexual
        orientation, religion, ethnicity, race, age, national origin, or
        disability; (f) to submit false or misleading information; (g) to
        transmit, or procure the sending of, any advertising or promotional
        including any “junk mail”, “chain letter”, “spam”, or any other similar
        solicitation; (h) to phish, pharm, pretext, spider, crawl, or scrape;
        (i) to upload or transmit viruses, Trojan horses, worms, logic bombs, or
        any other type of malicious code that will or may be used in any way
        that will affect the functionality or operation of the Services or of
        any related services, or the Internet; (j) to collect or track the
        personal information of others; (k) for any obscene or immoral purpose;
        (l) to impersonate or attempt to impersonate Company, a Company
        employee, another user, or any other person or entity, including,
        without limitation, by using email addresses associated with any of the
        foregoing; (m) to engage in any other conduct that restricts or inhibits
        anyone’s use or enjoyment of the Services, or which, as determined by
        us, may harm Company or users of the Services or expose them to
        liability; or (n) to interfere with or circumvent the security features
        of the Services or any related services, or the Internet.
        <br />
        <br />
        We reserve the right to terminate your use of the Services or any
        related services for violating any provision of these Terms.
        <br />
        <br />
        <strong>
          RESERVATION OF RIGHTS
          <br />
        </strong>
        You acknowledge and agree that the Services are provided under license,
        and not sold, to you. You do not acquire any ownership interest in the
        Services under these Terms, or any other rights thereto other than to
        use the Services in accordance with the license granted, and subject to
        all terms, conditions, and restrictions, under these Terms. Company and
        its licensors and service providers reserve and shall retain their
        entire right, title, and interest in and to the Services, including all
        copyrights, trademarks, and other intellectual property rights therein
        or relating thereto, except as expressly granted to you in these Terms.
        <br />
        <br />
        <strong>
          COLLECTION AND USE OF YOUR INFORMATION
          <br />
        </strong>
        You acknowledge that when you download, install, or use the Services,
        Digital Base may use automatic means (including, for example, cookies)
        to collect non-personal information about your Device and about your use
        of the Services. All information we collect through or in connection
        with the Services is subject to our Privacy Policy. By downloading,
        installing, using, and providing information to or through the services,
        you consent to all actions taken by us with respect to your information
        in compliance with the Privacy Policy.
        <br />
        <br />
        <strong>
          UPDATES
          <br />
        </strong>
        Digital Base&nbsp; may occasionally in its sole discretion develop and
        provide updates to the Services, which may include upgrades, bug fixes,
        patches, other error corrections, and/or new features (collectively,
        including related documentation, “Updates”). Updates may also modify or
        delete in their entirety certain features and functionality. You agree
        that Digital Base has no obligation to provide any Updates or to
        continue to provide or enable any particular features or functionality.
        <br />
        <br />
        <strong>
          THIRD PARTY MATERIALS
          <br />
        </strong>
        The Services may display, include, or make available third-party content
        (including data, information, applications, and other products,
        services, and/or materials) or provide links to third-party websites or
        services, including through third-party advertising (”Third-Party
        Materials”). You acknowledge and agree that Company is not responsible
        for Third-Party Materials, including their accuracy, completeness,
        timeliness, validity, copyright compliance, legality, decency, quality,
        or any other aspect thereof. Company does not assume and will not have
        any liability or responsibility to you or any other person or entity for
        any Third-Party Materials. Third-Party Materials and links thereto are
        provided solely as a convenience to you, and you access and use them
        entirely at your own risk and subject to such third parties’ terms and
        conditions.
        <br />
        <br />
        <strong>
          TERM AND TERMINATION
          <br />
        </strong>
        The obligations and liabilities of the parties incurred prior to the
        termination date shall survive the termination of these Terms for all
        purposes. These Terms are effective unless and until terminated by
        either you or us. You may terminate these Terms by deleting the Services
        and all copies thereof from your Devices. If in our sole judgment you
        fail, or we suspect that you have failed, to comply with any term or
        provision of these Terms, we also may terminate these Terms at any time
        without notice and you will remain liable for all amounts due up to and
        including the date of termination; and/or accordingly may deny you
        access to the Services (or any part thereof).
        <br />
        <br />
        <strong>
          DISCLAIMER OF WARRANTIES
          <br />
        </strong>
        THE SERVICES ARE PROVIDED TO LICENSEE “AS IS” AND WITH ALL FAULTS AND
        DEFECTS WITHOUT WARRANTY OF ANY KIND. TO THE MAXIMUM EXTENT PERMITTED
        UNDER APPLICABLE LAW, COMPANY, ON ITS OWN BEHALF AND ON BEHALF OF ITS
        AFFILIATES AND ITS AND THEIR RESPECTIVE LICENSORS AND SERVICE PROVIDERS,
        EXPRESSLY DISCLAIMS ALL WARRANTIES, WHETHER EXPRESS, IMPLIED, STATUTORY,
        OR OTHERWISE, WITH RESPECT TO THE SERVICES, INCLUDING ALL IMPLIED
        WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE,
        AND NON-INFRINGEMENT, AND WARRANTIES THAT MAY ARISE OUT OF COURSE OF
        DEALING, COURSE OF PERFORMANCE, USAGE, OR TRADE PRACTICE. WITHOUT
        LIMITATION TO THE FOREGOING, COMPANY PROVIDES NO WARRANTY OR
        UNDERTAKING, AND MAKES NO REPRESENTATION OF ANY KIND THAT THE SERVICES
        WILL MEET YOUR REQUIREMENTS, ACHIEVE ANY INTENDED RESULTS, BE
        COMPATIBLE, OR WORK WITH ANY OTHER SOFTWARE, APPLICATIONS, SYSTEMS, OR
        SERVICES, OPERATE WITHOUT INTERRUPTION, MEET ANY PERFORMANCE OR
        RELIABILITY STANDARDS, BE ERROR-FREE, OR THAT ANY ERRORS OR DEFECTS CAN
        OR WILL BE CORRECTED.
        <br />
        SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF OR LIMITATIONS ON
        IMPLIED WARRANTIES OR THE LIMITATIONS ON THE APPLICABLE STATUTORY RIGHTS
        OF A CONSUMER, SO SOME OR ALL OF THE ABOVE EXCLUSIONS AND LIMITATIONS
        MAY NOT APPLY TO YOU.
        <br />
        <br />
        <strong>
          LIMITATION OF LIABILITY
          <br />
        </strong>
        TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT WILL
        COMPANY OR ITS AFFILIATES, OR ANY OF ITS OR THEIR RESPECTIVE LICENSORS
        OR SERVICE PROVIDERS, OR THEIR RESPECTIVE OWNERS, MEMBERS, OFFICERS,
        DIRECTORS, EMPLOYEES, AGENTS, REPRESENTATIVES, CONTRACTORS,
        SUB-CONTRACTORS, SUCCESSORS OR ASSIGNS HAVE ANY LIABILITY ARISING FROM
        OR RELATED TO YOUR USE OF OR INABILITY TO USE THE SERVICES OR THE
        CONTENT AND SERVICES FOR: (a) PERSONAL INJURY, PROPERTY DAMAGE, LOST
        PROFITS, COST OF SUBSTITUTE GOODS OR SERVICES, LOSS OF DATA, LOSS OF
        GOODWILL, BUSINESS INTERRUPTION, COMPUTER FAILURE OR MALFUNCTION, OR ANY
        OTHER CONSEQUENTIAL, INCIDENTAL, INDIRECT, EXEMPLARY, SPECIAL, OR
        PUNITIVE DAMAGES, OR (b) DIRECT DAMAGES IN AMOUNTS THAT IN THE AGGREGATE
        EXCEED THE AMOUNT ACTUALLY PAID BY YOU FOR THE SERVICES.
        <br />
        <br />
        THE FOREGOING LIMITATIONS WILL APPLY WHETHER SUCH DAMAGES ARISE OUT OF
        br /EACH OF CONTRACT, TORT (INCLUDING NEGLIGENCE), OR OTHERWISE AND
        REGARDLESS OF WHETHER SUCH DAMAGES WERE FORESEEABLE OR COMPANY WAS
        ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. SOME JURISDICTIONS DO NOT
        ALLOW CERTAIN LIMITATIONS OF LIABILITY, SO SOME OR ALL OF THE ABOVE
        LIMITATIONS OF LIABILITY MAY NOT APPLY TO YOU.
        <br />
        <br />
        <strong>
          INDEMNIFICATION
          <br />
        </strong>
        You agree to indemnify, defend and hold harmless Digital Base, and its
        parent, subsidiaries, affiliates, partners, successors, and assigns, and
        each of their owners, members, officers, directors, employees, agents,
        representatives, contractors, subcontractors, licensors, service
        providers, and third party content providers from and against any and
        all losses, damages, liabilities, deficiencies, claims, actions,
        judgments, settlements, interest, awards, penalties, fines, costs, fees,
        including reasonable attorneys’ fees, or expenses of whatever kind
        arising from or relating to your use or misuse of the Services or your
        br /each of these Terms, including but not limited to the content you
        submit or make available through the Services.
        <br />
        <br />
        <strong>
          SEVERABILITY
          <br />
        </strong>
        If any provision of these Terms is illegal or unenforceable under
        applicable law, the remainder of the provision will be amended to
        achieve as closely as possible the effect of the original term and all
        other provisions of these Terms will continue in full force and effect.
        <br />
        <br />
        <strong>
          GOVERNING LAW
          <br />
        </strong>
        Any claim, dispute, and/or legal proceeding relating to these Terms
        and/or the Services (collectively, “Claims“), shall be governed by and
        construed in accordance with the laws Dubai, U.A.E without giving effect
        to any choice or conflict of law provision or rule. Except as provided
        below under the Arbitration clause, any Claim shall be instituted
        exclusively in the courts of Dubai, United Arab Emirates. You waive any
        and all objections to the exercise of jurisdiction over you by such
        courts and to venue in such courts.
        <br />
        <br />
        <strong>
          LIMITATION OF TIME TO FILE CLAIMS
          <br />
        </strong>
        ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF OR RELATING TO
        THESE TERMS OR THE SERVICES MUST BE COMMENCED WITHIN ONE (1) YEAR AFTER
        THE CAUSE OF ACTION ACCRUES OTHERWISE SUCH CAUSE OF ACTION OR CLAIM IS
        PERMANENTLY BARRED.
        <br />
        <br />
        <strong>
          ARBITRATION
          <br />
        </strong>
        PLEASE READ THIS ARBITRATION CLAUSE CAREFULLY.
        <br />
        Except for Claims relating to intellectual property rights, obligations,
        or any infringement claims, or Claims that qualify for small claims
        court, all Claims to the extent allowed by law, shall be submitted to
        confidential binding arbitration on an individual basis in Dubai, UAE,
        and you consent to personal jurisdiction, venue and forum without
        objection. You agree that all such Claims must be br /ought within one
        year after such claim arose, otherwise, your Claim is permanently
        barred.
        <br />
        <br />
        The arbitration shall be conducted by a single, neutral arbitrator. Any
        judgment on the award rendered by the arbitrator may be entered in any
        court of competent jurisdiction. Each party shall bear its own costs
        (including attorney’s fees) and shall pay an equal share of the fees and
        costs of arbitration. The parties hereby waive their constitutional and
        statutory rights to go to court and have a trial before a judge or jury.
        All Claims within the scope of this arbitration agreement must be
        arbitrated or litigated on an individual basis and not on a class action
        basis and claims of more than one user cannot be arbitrated or resolved
        jointly or consolidated with any other user.
        <br />
        Either party may obtain injunctive relief (preliminary or permanent) and
        orders to compel arbitration or enforce arbitral awards in a court of
        competent jurisdiction, and you consent to the exclusive jurisdiction
        and venue of court in Dubai, U.A.E.
        <br />
        <br />
        <strong>
          WAIVER
          <br />
        </strong>
        No failure to exercise, and no delay in exercising, on the part of
        either party, any right or any power hereunder shall operate as a waiver
        thereof, nor shall any single or partial exercise of any right or power
        hereunder preclude further exercise of that or any other right
        hereunder. In the event of a conflict between these Terms and any
        applicable purchase or other terms, the provisions of these Terms shall
        govern.
        <br />
        <br />
        <strong>
          ENTIRE AGREEMENT
          <br />
        </strong>
        These Terms and our Privacy Policy constitute the entire agreement
        between you and Company with respect to the Services and supersede all
        prior or contemporaneous understandings and agreements, whether written
        or oral, with respect to the Services.
        <br />
        <br />
        <strong>
          HOW TO CONTACT US
          <br />
        </strong>
        Please contact us at the mailing address, email, or phone number below
        with questions about our Terms of Service:
        <br />
        <br />
        Digital Base L.L.C-FZ
        <br />
        Business Center 1, M Floor,&nbsp;
        <br />
        The Meydan Hotel,&nbsp;
        <br />
        Nad Al Sheba,&nbsp;
        <br />
        Dubai, U.A.E.
        <br />
        <br />
        Email:{" "}
        <a href="mailto:support@digitalbase.me" className="email-link">
          support@digitalbase.me
        </a>
      </p>
    </div>
  );
};

export default Terms;
