import _axios from "axios";
import { BASE_URL } from "../constants";

const axios = _axios.create({
  baseURL: BASE_URL,
  validateStatus: () => {
    return true;
  },
});

export default axios;
